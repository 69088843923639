import React, { Component } from "react";
import NavBar from "./navbar";

class Resume extends Component {
  render() {
    return (
      <div className="resume">
        <NavBar></NavBar>
      </div>
    );
  }
}

export default Resume;
